<template>
  <div class="bottom">
    <div class="center">
      <ul class="bottom-list" style="width: 520px">
        <li style="color: white; width: 58%; margin-top: 4px; font-size: 13px">
          主管单位：四川省人民政府办公厅
        </li>
        <li style="color: white; width: 42%; margin-top: 4px; font-size: 13px">
          网站标识码：5100000042
        </li>
        <li style="color: white; width: 58%; margin-top: 4px; font-size: 13px">
          主办单位：四川省人力资源和社会保障厅
        </li>
        <li
          style="
            color: white;
            width: 42%;
            margin-top: 4px;
            font-size: 13px;
            letter-spacing: 1px;
          "
        >
          ICP备案号：蜀ICP备20024042号
        </li>
        <li style="color: white; width: 58%; margin-top: 4px; font-size: 13px">
          承办单位：四川省人力资源和社会保障信息中心
        </li>
        <li style="color: white; color: white; width: 42%; margin-top: 4px">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010402000507"
            style="line-height: 20px; font-size: 13px"
          >
            <img
              width="15"
              height="15"
              :src="img"
              style="vertical-align: middle"
            />
            川公网安备：51010402000507号
          </a>
        </li>
      </ul>
      <ul class="bottom-sm">
            <li>
                <a href=" 
http://rst.sc.gov.cn/rst/lxfs/2019/11/27/d34a904168154549884f4d08c0934fad.shtml
" target="_blank">
                    <i class="bottom-icon bottom-icon-1"></i>
                    <p>
                        联系方式
                    </p>
                </a>
            </li>
            <li>
                <a href=" 
http://rst.sc.gov.cn/rst/wzdt/2020/1/16/2adbf4f367dc4252b8618dca075c5e48.shtml
" target="_blank">
                    <i class="bottom-icon bottom-icon-2"></i>
                    <p>
                        网站地图
                    </p>
                </a>
            </li>
            <li>
                <a href=" 
http://rst.sc.gov.cn/rst/sybz/2020/1/21/1e4aa96ff75d41708411af8d59c91f9f.shtml
" target="_blank">
                    <i class="bottom-icon bottom-icon-3"></i>
                    <p>
                        使用帮助
                    </p>
                </a>
            </li>
            <li>
                <a href=" 
http://rst.sc.gov.cn/rst/wzsm/2019/11/27/5f07a7b8ee1d4b469064c34e55913637.shtml
" target="_blank">
                    <i class="bottom-icon bottom-icon-4"></i>
                    <p>
                        网站声明
                    </p>
                </a>
            </li>
        </ul>
      <div class="bottom-rt">
        <a
          href="http://bszs.conac.cn/sitename?method=show&amp;id=09C305A2F0A950A4E053012819ACE3E5"
          class="bottom-rt1"
          target="_blank"
        >
          <img :src="img1" />
        </a>
        <a
          href="http://121.43.68.40/exposure/jiucuo.html?site_code=5100000042&amp;url=http%3A%2F%2Fwww.sc.hrss.gov.cn%2F"
          class="bottom-rt2"
          target="_blank"
        >
          <img :src="img2" />
        </a>
        <a href="https://scpiyao.org.cn/" class="bottom-rt2" target="_blank">
          <img :src="img3" style="width: 110px; height: 55px" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import img from "@/assets/images/wzdbgh.png";
import img1 from "@/assets/images/red.png";
import img2 from "@/assets/images/jiucuo.png";
import img3 from "@/assets/images/scjbpy.png";

export default {
  name: "index-footer",
  data() {
    return {
      img,
      img1,
      img2,
      img3,
    };
  },
  methods: {},
};
</script>

<style scoped>
.bottom {
  width: auto;
  height: 100px;
  background: #328ad1;
  color: #ffffff;
  padding-top: 26px;
  box-sizing: content-box;
}
.center {
  padding: 0;
  font-family: "Microsoft YaHei", "Hiragino Sans GB";
  font-size: 12px;
  color: #000000;
  list-style: none;
  width: 1200px;
  height: auto;
  margin: 0 auto;
}
.bootom-list {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei", "Hiragino Sans GB";
  font-size: 12px;
  color: #000000;
  list-style: none;
  float: left;
  width: 520px;
}
.bottom-sm {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei", "Hiragino Sans GB";
  font-size: 12px;
  color: #000000;
  list-style: none;
  float: left;
  border-left: 1px solid rgba(254, 254, 254, 0.5);
  border-right: 1px solid rgba(254, 254, 254, 0.5);
  width: 350px;
  padding-left: 0px;
}
.bottom-rt {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei", "Hiragino Sans GB";
  font-size: 12px;
  color: #000000;
  list-style: none;
  float: right;
}
.bottom ul.bottom-list li {
  width: 50%;
  float: left;
}
.bottom ul.bottom-sm li {
  width: 23%;
  display: inline-block;
  text-align: center;
}
a {
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}
.bottom-rt .bottom-rt2 img {
  margin-top: 8px;
  margin-right: 10px;
}
.bottom-rt img {
  float: right;
}
.bottom ul.bottom-list li a {
  font-size: 13px;
  color: #ffffff;
  line-height: 2;
}
.bottom ul.bottom-list {
  width: 530px;
  float: left;
  padding: 0;
}
.bottom-sm a p {
  font-size: 12px;
  color: #ffffff;
  line-height: 2;
}
</style>
